import { setUpdatePage } from 'sdk/messages/creators';

import { sendMessage } from '.';

/**
 * Send next url to display in app
 */
export const sendUpdatePage =
    ({ nextUrl }: Record<string, any>) =>
    (dispatch: any) => {
        dispatch(sendMessage(setUpdatePage(nextUrl)));
    };
