import iframeWrapper from 'sdk/IFrameWrapper';
import { SDKState } from 'sdk/reducers';

import { HandleErrorAction } from '../../sagas/actionCreators';

/**
 * Thunk called when an HTTP error occurred in Partoo App.
 *
 * @param errorType {number} HTTP error code (400, 404, 500,...)
 */
export const onError =
    ({ errorType }: HandleErrorAction) =>
    async (dispatch: any, getState: () => SDKState) => {
        iframeWrapper.displayErrorView();
        const callbacks = getState().callbacks;
        const cb = callbacks['error'];

        if (cb) {
            cb(errorType);
        } else {
            console.warn(`Unexpected error of type ${errorType}`);
        }
    };
