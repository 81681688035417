import { rejectAction } from 'sdk/messages/creators';

import { SendUnregisteredCallbackAction } from 'sdk/sagas/actionCreators';

import { sendMessage } from '.';

/**
 * Send message to indicate that a callback has been canceled from a Partoo App action
 *
 * @param action {string} Partoo app action for which we canceled a callback
 */
export const sendUnregisteredCallback =
    ({ action }: SendUnregisteredCallbackAction) =>
    (dispatch: any) => {
        dispatch(sendMessage(rejectAction(action)));
    };
