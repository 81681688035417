// MESSAGE TYPES
import { Options } from 'sdk/reducers/page';

export const OVERRIDE_ACTION = 'OVERRIDE_ACTION';
export const REJECT_ACTION = 'REJECT_ACTION';
export const OVERRIDE_STYLE = 'OVERRIDE_STYLE';
export const SET_OPTIONS = 'SET_OPTIONS';
export const GET_PAGE_SIZE = 'GET_PAGE_SIZE';
export const SET_UPDATE_PAGE = 'SET_UPDATE_PAGE';
export const SET_SEED_DATA = 'SET_SEED_DATA';

// MESSAGE FLOW TYPES
export type OverrideActionMessage = {
    type: 'OVERRIDE_ACTION';
    action: string;
};

export type RejectActionMessage = {
    type: 'REJECT_ACTION';
    action: string;
};

export type OverrideStyleMessage = {
    type: 'OVERRIDE_STYLE';
};

export type GetPageSizeMessage = {
    type: 'GET_PAGE_SIZE';
};

export type SetOptionsMessage = {
    type: 'SET_OPTIONS';
    options: Options;
};

export type SetUpdatePageMessage = {
    type: 'SET_UPDATE_PAGE';
    nextUrl: string;
};

export type SetSeedDataMessage = {
    type: 'SET_SEED_DATA';
    seedData: Record<string, any>;
};

export type JsApiMessage =
    | OverrideActionMessage
    | RejectActionMessage
    | OverrideStyleMessage
    | GetPageSizeMessage
    | SetSeedDataMessage
    | SetUpdatePageMessage
    | SetOptionsMessage;
