export const isUrl = (urlString: string): boolean => {
    try {
        const url = new URL(urlString);
        return (
            url.protocol === 'http:' ||
            url.protocol === 'https:' ||
            url.protocol === 'ftp:'
        );
    } catch (error) {
        return false;
    }
};

const PARTOO_APP_PROD_HOSTNAME = 'app.partoo.co';
const PARTOO_ADMIN_PROD_HOSTNAME = 'admin.partoo.co';
const MEDIAPOST_PROD_HOSTNAME = 'app.mavisibiliteweb.fr';
const JSSDK_PROD_HOSTNAME = 'https://jssdk-playground.partoo.co';

export const extractPartooSubdomain = () => {
    if (
        [
            PARTOO_APP_PROD_HOSTNAME,
            PARTOO_ADMIN_PROD_HOSTNAME,
            MEDIAPOST_PROD_HOSTNAME,
            JSSDK_PROD_HOSTNAME,
        ].includes(window.location.hostname)
    )
        return '';

    const domainPattern =
        /(?:app|admin|jssdk-playground)\.([^.]+)\.partoo\.co/i;
    const match = window.location.hostname.match(domainPattern);

    return match && match.length >= 2 ? match[1] : null;
};
