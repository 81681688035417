import iframeWrapper from 'sdk/IFrameWrapper';

import { HandlePageHeightAction, sendGetSizeRequest } from '../../sagas/actionCreators';

/**
 * Thunk triggered when a JS message containing Partoo App height is received.
 * It adapts the size of the container to this height, wait for 1s and request
 * the Partoo App height again.
 *
 * @param height {number} Partoo App height in pixels
 */
export const onPageHeight =
    ({ height }: HandlePageHeightAction) =>
    async (dispatch: any) => {
        iframeWrapper.resizeContainerToIFrameHeight(height);
        await new Promise(resolve => setTimeout(resolve, 1000));
        dispatch(sendGetSizeRequest());
    };
