/**
 * `ReduxConnectedClass` class & `connect` function aims at reproducing
 * the behaviour of `Component` class & `connect` in a React-Redux project
 */

import { Store } from 'redux';

import { SDKState } from 'sdk/reducers';

/**
 * Class that is connected to a redux store.
 * It has 2 main attributes:
 *    - `state`: Object containing the properties coming from the redux state
 *    - `dispatchers`: Object containing the functions that dispatch redux action to the store
 */
class ReduxConnectedClass<S, D> {
    // Previous class state
    prevState: S;

    // Current class state
    state: S;

    // Functions that dispatch redux action
    dispatchers: D;

    /**
     * Update class state & call `onStateChange` callback
     *
     * @param state {S} New class state
     */
    updateState(state: S) {
        if (this.state) {
            this.prevState = { ...this.state };
        }

        this.state = { ...state };
        this.onStateChange(this.prevState, this.state);
    }

    /**
     * Callback called each time the state is updated.
     *
     * @param prevState {S} Previous state
     * @param state {S} Current state
     */

    onStateChange(prevState: S, state: S): void {
        /* Empty */
    }

    /**
     * Update dispatchers functions
     *
     * @param dispatchers {D}
     */
    updateDispatchers(dispatchers: D) {
        this.dispatchers = { ...dispatchers };
    }

    /**
     * Set initial state
     *
     * @param state
     */
    loadInitialState(state: S) {
        this.state = { ...state };
    }
}
/**
 * Connect a ReduxConnectedClass instance to a Redux store.
 *
 * @param store {Store} The redux store
 * @param instance {ReduxConnectedClass}
 * @param mapReduxStateToClassState {Function|null} Map store state to class state
 * @param mapDispatchToDispatchers {Function|null} Map store dispatch to class dispatchers
 */

export const connect = function <S, D>(
    store: Store<SDKState>,
    instance: ReduxConnectedClass<S, D>,
    mapReduxStateToClassState?: null | ((state: SDKState) => S),
    mapDispatchToDispatchers?: null | ((dispatch: any, state: SDKState) => D),
) {
    // Subscribe for future changes
    store.subscribe(() => {
        const state = store.getState();

        if (mapDispatchToDispatchers) {
            instance.updateDispatchers(mapDispatchToDispatchers(store.dispatch, state));
        }

        if (mapReduxStateToClassState) {
            instance.updateState(mapReduxStateToClassState(state));
        }
    });

    // initial setting
    const state = store.getState();
    if (mapDispatchToDispatchers) {
        instance.updateDispatchers(mapDispatchToDispatchers(store.dispatch, state));
    }
    if (mapReduxStateToClassState) {
        instance.loadInitialState(mapReduxStateToClassState(state));
    }
};

export default ReduxConnectedClass;
