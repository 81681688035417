const defautStyle = `
<style type="text/css">
    @-webkit-keyframes lds-microsoft {
      0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes lds-microsoft {
      0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    #partoo-loading {
        visibility: visible;
        height: 200px;
        padding-top: 30%;
        padding-bottom: 30%;
        padding-left: 40%;
        background-color: white;
    }
    .lds-microsoft {
      position: relative;
      background-color: #00a2e8;
    }
    .partoo-error-container {
      height: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .partoo-error {
      color: red;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-family: Helvetica, Arial, sans-serif;
      font-size: 30px;
      height: 200px;
    }
    .partoo-error-icon {
      margin-bottom: 20px;
    }
    .lds-microsoft div {
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      -webkit-animation: lds-microsoft 1.5s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      animation: lds-microsoft 1.5s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    }
    .lds-microsoft div:nth-of-type(0) {
      background: rgb(0, 179, 253);
      top: 134.613px;
      left: 147.535px;
      -webkit-transform-origin: -47.535px -34.613px;
      transform-origin: -47.535px -34.613px;
      -webkit-animation-delay: 0s;
      animation-delay: 0s;
    }
    .lds-microsoft div:nth-of-type(1) {
      background: rgb(0, 179, 253);
      top: 147.535px;
      left: 134.613px;
      -webkit-transform-origin: -34.613px -47.535px;
      transform-origin: -34.613px -47.535px;
      -webkit-animation-delay: -0.062s;
      animation-delay: -0.062s;
    }
    .lds-microsoft div:nth-of-type(2) {
      background: rgb(0, 179, 253);
      top: 156.672px;
      left: 118.788px;
      -webkit-transform-origin: -18.788px -56.672px;
      transform-origin: -18.788px -56.672px;
      -webkit-animation-delay: -0.125s;
      animation-delay: -0.125s;
    }
    .lds-microsoft div:nth-of-type(3) {
      background: rgb(0, 179, 253);
      top: 161.401px;
      left: 101.137px;
      -webkit-transform-origin: -1.137px -61.401px;
      transform-origin: -1.137px -61.401px;
      -webkit-animation-delay: -0.187s;
      animation-delay: -0.187s;
    }
    .lds-microsoft div:nth-of-type(4) {
      background: rgb(0, 179, 253);
      top: 161.401px;
      left: 82.863px;
      -webkit-transform-origin: 17.137px -61.401px;
      transform-origin: 17.137px -61.401px;
      -webkit-animation-delay: -0.25s;
      animation-delay: -0.25s;
    }
    .lds-microsoft div:nth-of-type(5) {
      background: rgb(0, 179, 253);
      top: 156.672px;
      left: 65.212px;
      -webkit-transform-origin: 34.788px -56.672px;
      transform-origin: 34.788px -56.672px;
      -webkit-animation-delay: -0.312s;
      animation-delay: -0.312s;
    }
    .lds-microsoft div:nth-of-type(6) {
      background: rgb(0, 179, 253);
      top: 147.535px;
      left: 49.387px;
      -webkit-transform-origin: 50.613px -47.535px;
      transform-origin: 50.613px -47.535px;
      -webkit-animation-delay: -0.375s;
      animation-delay: -0.375s;
    }
    .lds-microsoft div:nth-of-type(7) {
      background: rgb(0, 179, 253);
      top: 134.613px;
      left: 36.465px;
      -webkit-transform-origin: 63.535px -34.613px;
      transform-origin: 63.535px -34.613px;
      -webkit-animation-delay: -0.437s;
      animation-delay: -0.437s;
    }
</style>
`;

export default defautStyle;
