import { isEmpty } from 'lodash-es';

import { sendSeedData as setSeedData } from 'sdk/messages/creators';
import { SDKState } from 'sdk/reducers';

import { sendMessage } from '.';

/**
 * [LEGACY] Send seed data to fill lab form
 */
export const sendSeedData = () => (dispatch: any, getState: () => SDKState) => {
    const seedData = getState().page.seedData;
    if (!isEmpty(seedData)) {
        dispatch(sendMessage(setSeedData(seedData)));
    }
};
