import iframeWrapper from 'sdk/IFrameWrapper';
import { JsApiMessage } from 'sdk/messages/types';
import { SDKState } from 'sdk/reducers';

/**
 * Thunk to send a JS message to Partoo App.
 * Before posting, check that messaging is enabled (i.e. the SDK bridge is ready to receive message)
 *
 * @param message {Object}
 */
export const sendMessage =
    (message: JsApiMessage) => async (dispatch: any, getState: () => SDKState) => {
        const { messagingEnabled } = getState().page;

        if (!messagingEnabled) {
            const result = await new Promise(resolve =>
                setTimeout(() => {
                    const { messagingEnabled } = getState().page;
                    resolve(messagingEnabled);
                }, 4000),
            );

            if (!result) {
                console.error('Messaging not working');
                return;
            }
        }

        iframeWrapper.postMessage(message);
    };
