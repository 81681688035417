import { overrideAction } from 'sdk/messages/creators';

import { SDKState } from 'sdk/reducers';

import { sendMessage } from '.';

/**
 * Thunk to send all the actions for which a callback has been defined
 * This saga is triggered at login time when the user successfully logged in.
 *
 */
export const sendOverriddenActions = () => (dispatch: any, getState: () => SDKState) => {
    const callbacks = getState().callbacks;
    Object.keys(callbacks).forEach(action => {
        dispatch(sendMessage(overrideAction(action)));
    });
};
