import iframeWrapper from 'sdk/IFrameWrapper';

import {
    UserStatus,
    UserStatuses,
    resetNavigationHistory,
    updateUserStatus,
} from 'sdk/reducers/page';
import NavigationService, { LOGOUT_ROUTE } from 'sdk/services/navigation';

export const logoutThunk = () => (dispatch: any) => {
    dispatch(updateUserStatus(UserStatuses.LOGGING_OUT as UserStatus));
    dispatch(resetNavigationHistory());

    const logoutUrl = NavigationService.generateURL(LOGOUT_ROUTE);
    iframeWrapper.updateIframeUrl(logoutUrl);
};
