import { AnyAction, Dispatch } from 'redux';

import { SDKState } from 'sdk/reducers';

import {
    SendRegisteredCallbackAction,
    SendUnregisteredCallbackAction,
} from 'sdk/sagas/actionCreators';

import {
    sendDisplayOptions,
    sendGetPageSizeRequest,
    sendOverriddenActions,
    sendOverrideStyle,
    sendRegisteredCallback,
    sendSeedData,
    sendUnregisteredCallback,
    sendUpdatePage,
} from '.';
import {
    SEND_DISPLAY_OPTIONS,
    SEND_GET_SIZE_REQUEST,
    SEND_OVERRIDDEN_ACTIONS,
    SEND_OVERRIDE_STYLE,
    SEND_REGISTERED_CALLBACK,
    SEND_SEED_DATA,
    SEND_UNREGISTERED_CALLBACK,
    SEND_UPDATE_PAGE,
} from '../event';

export type SendEventMiddleWareActions = AnyAction;

export const sendMessageMiddleware =
    ({ dispatch, getState }: { dispatch: Dispatch<AnyAction>; getState: () => SDKState }) =>
    (next: (action: SendEventMiddleWareActions) => void) =>
    (action: SendEventMiddleWareActions) => {
        switch (action.type) {
            case SEND_DISPLAY_OPTIONS:
                sendDisplayOptions()(dispatch, getState);
                break;
            case SEND_OVERRIDDEN_ACTIONS:
                sendOverriddenActions()(dispatch, getState);
                break;
            case SEND_UPDATE_PAGE:
                sendUpdatePage(action)(dispatch);
                break;
            case SEND_SEED_DATA:
                sendSeedData()(dispatch, getState);
                break;
            case SEND_GET_SIZE_REQUEST:
                sendGetPageSizeRequest()(dispatch);
                break;
            case SEND_OVERRIDE_STYLE:
                sendOverrideStyle()(dispatch);
                break;
            case SEND_REGISTERED_CALLBACK:
                sendRegisteredCallback(action as SendRegisteredCallbackAction)(dispatch);
                break;
            case SEND_UNREGISTERED_CALLBACK:
                sendUnregisteredCallback(action as SendUnregisteredCallbackAction)(dispatch);
                break;
            default:
                break;
        }

        return next(action);
    };
