import { AnyAction, Dispatch } from 'redux';

import { SDKState } from 'sdk/reducers';

import { LoginUserActionWithPage, NavigateToAction } from 'sdk/sagas/actionCreators';

import { goBackThunk, goForwardThunk, loginThunk, logoutThunk, navigateToThunk } from '.';
import { GO_BACK, GO_FORWARD, LOGIN_USER, LOGOUT_USER, NAVIGATE_TO } from '../event';

export type NavigateActions = LoginUserActionWithPage | NavigateToAction | AnyAction;

export const navigateMiddleware =
    ({ dispatch, getState }: { dispatch: Dispatch<AnyAction>; getState: () => SDKState }) =>
    (next: (action: NavigateActions) => void) =>
    (action: NavigateActions) => {
        switch (action.type) {
            case LOGOUT_USER:
                logoutThunk()(dispatch);
                break;
            case LOGIN_USER:
                const loginAction = action as LoginUserActionWithPage;
                loginThunk(loginAction.tokenOrApiKey, loginAction.nextPage)(dispatch, getState);
                break;
            case GO_BACK:
                goBackThunk()(dispatch, getState);
                break;
            case GO_FORWARD:
                goForwardThunk()(dispatch, getState);
                break;
            case NAVIGATE_TO:
                const { route, additionalParams, seedData } = action as NavigateToAction;
                navigateToThunk(route, additionalParams, seedData)(dispatch, getState);
                break;
            default:
                break;
        }

        return next(action);
    };
