import { AnyAction, Dispatch } from 'redux';

import { SDKState } from 'sdk/reducers';

import {
    HandleCallbackCalledAction,
    HandleErrorAction,
    HandlePageChangedAction,
    HandlePageHeightAction,
    HandleUserLoggedInAction,
    HandleUserLoggedOutAction,
} from 'sdk/sagas/actionCreators';

import {
    onCallbackCalled,
    onError,
    onIFrameJSHasLoaded,
    onPageChanged,
    onPageHeight,
    onStyleOverriden,
    onUserLoggedIn,
    onUserLoggedOut,
} from '.';
import {
    HANDLE_CALLBACK_CALLED_MESSAGE,
    HANDLE_ERROR_MESSAGE,
    HANDLE_IFRAME_JS_LOADED_MESSAGE,
    HANDLE_PAGE_CHANGED_MESSAGE,
    HANDLE_PAGE_HEIGHT_MESSAGE,
    HANDLE_STYLE_IS_OVERRIDDEN_MESSAGE,
    HANDLE_USER_LOGGED_IN_MESSAGE,
    HANDLE_USER_LOGGED_OUT_MESSAGE,
} from '../event';

export type HandleEventMiddlewareActions =
    | HandleUserLoggedInAction
    | HandleUserLoggedOutAction
    | HandlePageChangedAction
    | HandleCallbackCalledAction
    | HandleErrorAction
    | HandlePageHeightAction
    | AnyAction;

export const handleEventMiddleware =
    ({ dispatch, getState }: { dispatch: Dispatch<AnyAction>; getState: () => SDKState }) =>
    (next: (action: HandleEventMiddlewareActions) => void) =>
    (action: HandleEventMiddlewareActions) => {
        switch (action.type) {
            case HANDLE_USER_LOGGED_OUT_MESSAGE:
                onUserLoggedOut()(dispatch);
                break;
            case HANDLE_USER_LOGGED_IN_MESSAGE:
                onUserLoggedIn()(dispatch);
                break;
            case HANDLE_CALLBACK_CALLED_MESSAGE:
                onCallbackCalled(action as HandleCallbackCalledAction)(dispatch, getState);
                break;
            case HANDLE_IFRAME_JS_LOADED_MESSAGE:
                onIFrameJSHasLoaded()(dispatch);
                break;
            case HANDLE_PAGE_HEIGHT_MESSAGE:
                onPageHeight(action as HandlePageHeightAction)(dispatch);
                break;
            case HANDLE_STYLE_IS_OVERRIDDEN_MESSAGE:
                onStyleOverriden();
                break;
            case HANDLE_PAGE_CHANGED_MESSAGE:
                onPageChanged(action as HandlePageChangedAction)(dispatch, getState);
                break;
            case HANDLE_ERROR_MESSAGE:
                onError(action as HandleErrorAction)(dispatch, getState);
                break;
            default:
                break;
        }

        return next(action);
    };
