import iframeWrapper from 'sdk/IFrameWrapper';
import { UserStatus, UserStatuses, updateUserStatus } from 'sdk/reducers/page';

/**
 * Thunk called when user was logged Out
 */
export const onUserLoggedIn = () => (dispatch: any) => {
    dispatch(updateUserStatus(UserStatuses.LOGGED_IN as UserStatus));
    iframeWrapper.displayIframe();
};
