import { SDKState } from 'sdk/reducers';
import { updatePage } from 'sdk/reducers/page';

import { HandlePageChangedAction } from '../../sagas/actionCreators';

/**
 * Thunk called when the iFrame URL has changed.
 *
 * @param url {string}
 */
export const onPageChanged =
    ({ url }: HandlePageChangedAction) =>
    (dispatch: any, getState: () => SDKState) => {
        const sdkState = getState();
        const previousUrl = sdkState.page.navigationHistory[sdkState.page.cursor];
        if (previousUrl !== url) {
            dispatch(updatePage(url));
        }
    };
