import { setOptions } from 'sdk/messages/creators';

import { SDKState } from 'sdk/reducers';

import { sendMessage } from '.';

/**
 * Thunk to send display options to Partoo App (for Partoo Component)
 */
export const sendDisplayOptions = () => (dispatch: any, getState: () => SDKState) => {
    const options = getState().page.options;
    dispatch(sendMessage(setOptions(options)));
};
