import {
    SDKState,
    selectedBusinessIdSelector,
    urlSelector,
    userStatusSelector,
} from 'sdk/reducers';
import { UserStatuses, resetSeedData, updatePage, updateSeedData } from 'sdk/reducers/page';

import { sendSeedData, sendUpdatePage } from 'sdk/sagas/actionCreators';
import NavigationService from 'sdk/services/navigation';

import { loginThunk } from './loginThunk';

/**
 * Thunk to navigate to a given route.
 *
 * @param route {string} Name of the route
 * @param additionalParams {Object}
 * @param seedData {Object}
 */

export const navigateToThunk =
    (route: string, additionalParams?: Record<string, any>, seedData?: any) =>
    async (dispatch: any, getState: () => SDKState) => {
        const state = getState();
        let userStatus = userStatusSelector(state);
        const currentUrl = urlSelector(state);
        const selectedBusinessId = selectedBusinessIdSelector(state);

        if (userStatus === UserStatuses.LOGGED_OUT) {
            await dispatch(loginThunk(route));
            userStatus = userStatusSelector(getState());
        }

        // If the user is logging in, wait until they are logged in
        while (userStatus === UserStatuses.LOGGING_IN) {
            await new Promise(resolve => setTimeout(resolve, 1000));
            userStatus = userStatusSelector(getState());
        }

        if (seedData) {
            dispatch(resetSeedData());
            dispatch(updateSeedData(seedData));
            dispatch(sendSeedData());
        }

        const nextUrl = NavigationService.generateURL(route, additionalParams, selectedBusinessId);
        if (currentUrl !== nextUrl) {
            dispatch(updatePage(nextUrl));
            const nextShortUrl = NavigationService.getShortUrlFromFullUrl(nextUrl);
            dispatch(sendUpdatePage(nextShortUrl));
        }
    };
