class PartooException {
    message: string;

    constructor(message) {
        this.message = message;
    }
}

export class RoutingException extends PartooException {}

export class InsertException extends PartooException {}

export class RemoveException extends PartooException {}

export class UndefinedCallbackException extends PartooException {}

export class NotAFunctionException extends PartooException {}

export class NotOverridableException extends PartooException {}
