import { SDKState } from 'sdk/reducers';

import { HandleCallbackCalledAction } from '../../sagas/actionCreators';

/**
 * Thunk triggered when an action, for which a callback has been defined,
 * is triggered in Partoo App.
 *
 * @param action {string} Name of the action
 * @param inputs {Object} Data return by the action.
 */
export const onCallbackCalled =
    ({ action, inputs }: HandleCallbackCalledAction) =>
    (dispatch: any, getState: () => SDKState) => {
        const callbacks = getState().callbacks;
        const cb = callbacks[action];

        if (!cb) {
            console.warn(`No callback defined for ${action}`);
        } else {
            cb(inputs);
        }
    };
