import { BASE_URL } from 'app/config';

import { InsertException } from './exceptions';
import defaultStyle from './htmlElements/defaultStyle';
import errorDiv from './htmlElements/errorDiv';
import iFrame from './htmlElements/iFrame';
import loadingDiv from './htmlElements/loadingDiv';
import { JsApiMessage } from './messages/types';

// Height in pixels
const CONTAINER_DEFAULT_HEIGHT = 900;

/**
 * Class responsible for:
 *   - handling the HTML elements inserted by Partoo SDK
 *   - posting JS Message to Partoo App inside the iFrame
 *   - updating URL of the iFrame
 */
export class IFrameWrapper {
    // Iframe containing the Partoo App
    iFrame: HTMLIFrameElement;

    // Div displayed when Partoo App is loading
    loadingDiv: HTMLDivElement;

    // Div displayed when the is an error inside Partoo App
    errorDiv: HTMLDivElement;

    // Div containing all the HTML elements
    containerDiv: HTMLDivElement;

    containerHeight: number = CONTAINER_DEFAULT_HEIGHT;

    heightDifferenceBetweenIFrameAndItsContent = 0;

    /**
     * Insert 3 HTML elements:
     *   - `loadingDiv`, div displayed when Partoo App is loading
     *   - `errorDiv`, div displayed when the is an error inside Partoo App
     *   - `Iframe`, iFrame containing the Partoo App
     * Display the `loadingDiv`
     *
     * @param elementId {string} Id of the div in which we are going to insert the HTML elements
     */
    insert = (elementId: string): void => {
        const receivingElement = document.getElementById(elementId);

        if (!receivingElement) {
            throw new InsertException(`There is no div whose id is ${elementId}`);
        }

        receivingElement.innerHTML = loadingDiv + errorDiv + iFrame + defaultStyle;

        this.containerDiv = receivingElement as any as HTMLDivElement;

        this.iFrame = document.getElementById('partoo-iframe') as any as HTMLIFrameElement;

        this.loadingDiv = document.getElementById('partoo-loading') as any as HTMLDivElement;

        this.errorDiv = document.getElementById('partoo-error') as any as HTMLDivElement;
    };

    /**
     * Remove all the HTML elements contained inside div identifier by `elementId`
     *
     * @param elementId {string}
     */
    static remove(elementId: string): void {
        const element = document.getElementById(elementId);

        if (element) {
            element.innerHTML = '';
        }
    }

    /**
     * Resize container div height to match Iframe content height
     *
     * @param height {number} Height of iFrame content
     */
    resizeContainerToIFrameHeight = (height: number): void => {
        if (
            this.containerHeight < height &&
            this.containerHeight - height !== this.heightDifferenceBetweenIFrameAndItsContent
        ) {
            this.heightDifferenceBetweenIFrameAndItsContent = this.containerHeight - height;
            this.containerDiv.style.height = `${height}px`;
            this.containerHeight = height;
        } else {
            this.containerDiv.style.height = `${this.containerHeight}px`;
        }
    };

    /**
     * Update the url attribute of the iFrame
     *
     * @param url {string}
     */
    updateIframeUrl = (url: string): void => this.iFrame.setAttribute('src', url);

    /**
     * Hide iFrame & error view & display loading view
     */
    displayLoadingView = (): void => {
        this.containerDiv.style.height = '';
        this.iFrame.style.visibility = 'hidden';
        this.errorDiv.style.display = 'none';
        this.loadingDiv.style.display = 'block';
    };

    /**
     * Hide loading & error views & display iFrame
     */
    displayIframe = (): void => {
        this.containerHeight = CONTAINER_DEFAULT_HEIGHT;
        this.iFrame.style.visibility = 'visible';
        this.errorDiv.style.display = 'none';
        this.loadingDiv.style.display = 'none';
    };

    /**
     * Hide iframe & loading view & display error view
     */
    displayErrorView = (): void => {
        this.containerDiv.style.height = '';
        this.iFrame.style.visibility = 'hidden';
        this.loadingDiv.style.display = 'none';
        this.errorDiv.style.display = 'block';
    };

    /**
     * Checks that iFrame content is not null
     *
     * @returns {boolean}
     */
    isIframeInserted = (): boolean => !!this.iFrame.contentWindow;

    /**
     * Post a JS message to the Partoo React App located inside the iFrame.
     *
     * @param message JsApiMessage
     * @returns {null|*}
     */
    postMessage = (message: JsApiMessage): void | null => {
        if (!this.isIframeInserted()) {
            return null;
        }

        return this.iFrame.contentWindow?.postMessage(message, BASE_URL);
    };
}
const iframeWrapper = new IFrameWrapper();

export default iframeWrapper;
