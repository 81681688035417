import { Reducer, combineReducers } from 'redux';

import callbacks, { CallbacksState } from './callbacks';
import page, { Options, PageState, UserStatus } from './page';

export type SDKState = {
    page: PageState;
    callbacks: CallbacksState;
};

const reducers: Reducer<SDKState> = combineReducers({
    callbacks,
    page,
});

export const pageSelector = (state: SDKState) => state.page;

export const callbacksSelector = (state: SDKState) => state.callbacks;

export const startPageSelector = (state: SDKState): string => state.page.options.startPage;

export const optionsSelector = (state: SDKState): Options => state.page.options;

export const seedDataSelector = (state: SDKState): Record<string, any> => state.page.seedData;

export const urlSelector = (state: SDKState): string | void =>
    state.page.navigationHistory[state.page.cursor];

export const userStatusSelector = ({ page: { userStatus } }: SDKState): UserStatus => userStatus;

export const selectedBusinessIdSelector = (state: SDKState): string | null =>
    state.page.options.selectedBusinessId;

export default reducers;
