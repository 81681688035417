import {
    CALLBACK_CALLED,
    ERROR_400_OCCURRED_IN_IFRAME,
    ERROR_403_OCCURRED_IN_IFRAME,
    ERROR_404_OCCURRED_IN_IFRAME,
    ERROR_500_OCCURRED_IN_IFRAME,
    IFRAME_JS_LOADED,
    PAGE_CHANGED,
    PAGE_SIZE_GIVEN,
    STYLE_OVERRIDDEN,
    USER_IS_LOGGED_IN,
    USER_IS_LOGGED_OUT,
} from 'app/SDKBridge/messages/types';

import ReduxConnectedClass from './redux-sdk';
import { MessageFromIFrame } from './types';

export type MessageListenerDispatchers = {
    callCallback: (action: string, inputs: Record<string, any>) => void;
    styleOverridden: () => void;
    handlePageHeight: (height: number) => void;
    handlePageChanged: (url: string) => void;
    handleJsLoaded: () => void;
    handleError: (errorType: number) => void;
    handleUserIsLoggedOut: () => void;
    handleUserIsLoggedIn: () => void;
};

/**
 * Class responsible for listening to JS message sent by Partoo App &
 * dispatching Redux action accordingly
 */
class MessageListener extends ReduxConnectedClass<{}, MessageListenerDispatchers> {
    eventListener: (...args: Array<any>) => any;

    constructor() {
        super();

        this.eventListener = message => this.dispatchMessage(message);

        window.addEventListener('message', this.eventListener);
    }

    /**
     * Dispatch Redux action according to JS Message
     *
     * @param data
     * @returns {void|*}
     */
    dispatchMessage = ({ data }: MessageFromIFrame): void => {
        switch (data.type) {
            case CALLBACK_CALLED:
                return this.dispatchers.callCallback(data.action, data.inputs);

            case STYLE_OVERRIDDEN:
                return this.dispatchers.styleOverridden();

            case PAGE_SIZE_GIVEN:
                return this.dispatchers.handlePageHeight(data.height);

            case PAGE_CHANGED:
                return this.dispatchers.handlePageChanged(data.url);

            case IFRAME_JS_LOADED:
                return this.dispatchers.handleJsLoaded();

            case USER_IS_LOGGED_IN:
                return this.dispatchers.handleUserIsLoggedIn();

            case USER_IS_LOGGED_OUT:
                return this.dispatchers.handleUserIsLoggedOut();

            case ERROR_400_OCCURRED_IN_IFRAME:
                return this.dispatchers.handleError(400);

            case ERROR_403_OCCURRED_IN_IFRAME:
                return this.dispatchers.handleError(403);

            case ERROR_404_OCCURRED_IN_IFRAME:
                return this.dispatchers.handleError(404);

            case ERROR_500_OCCURRED_IN_IFRAME:
                return this.dispatchers.handleError(500);

            default:
                return console.warn('⚠️ No action configured for message', data);
        }
    };

    destroyListener = (): void => {
        window.removeEventListener('message', this.eventListener);
    };
}

export default MessageListener;
