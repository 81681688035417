const loadingDiv = `<div id="partoo-loading" style="visibility: visible;">
    <div class="lds-microsoft" />
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>`;

export default loadingDiv;
