import { CONNECTION_PATH } from 'app/routing/routeIds';

import { SDKState, urlSelector } from 'sdk/reducers';
import { goToPreviousPage } from 'sdk/reducers/page';
import NavigationService from 'sdk/services/navigation';

import { sendUpdatePage } from '../../sagas/actionCreators';

/**
 * Thunk to go back in navigation history.
 */
export const goBackThunk = () => async (dispatch: any, getState: () => SDKState) => {
    const state = getState();
    const currentUrl = urlSelector(state);

    // Dispatch goToPreviousPage action to update navigation state
    dispatch(goToPreviousPage());

    const previousUrl = urlSelector(getState()); // get the updated URL after dispatch

    // Skip if the previous URL includes CONNECTION_PATH
    if (previousUrl?.includes(CONNECTION_PATH)) return;

    // If the URL has changed, update it with the short version
    if (previousUrl && currentUrl !== previousUrl) {
        const previousShortUrl = NavigationService.getShortUrlFromFullUrl(previousUrl);
        dispatch(sendUpdatePage(previousShortUrl));
    }
};
