const iFrame = `<iframe
    style="visibility: hidden;"
    id='partoo-iframe'
    src=''
    width='100%'
    height='100%'
    frameborder='0'
    scrolling="no"
    allow="clipboard-write"
></iframe>`;

export default iFrame;
