export const SEND_DISPLAY_OPTIONS = 'SEND_DISPLAY_OPTIONS';
export const SEND_OVERRIDDEN_ACTIONS = 'SEND_OVERRIDDEN_ACTIONS';
export const SEND_UPDATE_PAGE = 'SEND_UPDATE_PAGE';
export const SEND_SEED_DATA = 'SEND_SEED_DATA';
export const SEND_GET_SIZE_REQUEST = 'SEND_GET_SIZE_REQUEST';
export const SEND_OVERRIDE_STYLE = 'SEND_OVERRIDE_STYLE';
export const SEND_REGISTERED_CALLBACK = 'SEND_REGISTERED_CALLBACK';
export const SEND_UNREGISTERED_CALLBACK = 'SEND_UNREGISTERED_CALLBACK';

export const HANDLE_USER_LOGGED_OUT_MESSAGE = 'HANDLE_USER_LOGGED_OUT_MESSAGE';
export const HANDLE_USER_LOGGED_IN_MESSAGE = 'HANDLE_USER_LOGGED_IN_MESSAGE';
export const HANDLE_CALLBACK_CALLED_MESSAGE = 'HANDLE_CALLBACK_CALLED_MESSAGE';
export const HANDLE_IFRAME_JS_LOADED_MESSAGE = 'HANDLE_IFRAME_JS_LOADED_MESSAGE';
export const HANDLE_PAGE_CHANGED_MESSAGE = 'HANDLE_PAGE_CHANGED_MESSAGE';
export const HANDLE_PAGE_HEIGHT_MESSAGE = 'HANDLE_PAGE_HEIGHT_MESSAGE';
export const HANDLE_STYLE_IS_OVERRIDDEN_MESSAGE = 'HANDLE_STYLE_IS_OVERRIDDEN_MESSAGE';
export const HANDLE_ERROR_MESSAGE = 'HANDLE_ERROR_MESSAGE';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const NAVIGATE_TO = 'NAVIGATE_TO';
export const GO_BACK = 'GO_BACK';
export const GO_FORWARD = 'GO_FORWARD';
