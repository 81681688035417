import { getPageSize } from 'sdk/messages/creators';

import { sendMessage } from '.';

/**
 * Thunk to send a page size request to Partoo App
 */
export const sendGetPageSizeRequest = () => (dispatch: any) => {
    dispatch(sendMessage(getPageSize()));
};
