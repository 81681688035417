import { overrideAction } from 'sdk/messages/creators';
import { registerCallback } from 'sdk/reducers/callbacks';
import { SendRegisteredCallbackAction } from 'sdk/sagas/actionCreators';

import { sendMessage } from '.';

/**
 * Send message to indicate that a callback has been defined for a Partoo App action
 *
 * @param action {string} Partoo app action for which we defined a callback
 * @param callback {Function} Callback to call when the action is triggered
 */
export const sendRegisteredCallback =
    ({ action, callback }: SendRegisteredCallbackAction) =>
    (dispatch: any) => {
        dispatch(registerCallback(action, callback));
        dispatch(sendMessage(overrideAction(action)));
    };
