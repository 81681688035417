// MESSAGE TYPES
import {
    GET_PAGE_SIZE,
    GetPageSizeMessage,
    OVERRIDE_ACTION,
    OVERRIDE_STYLE,
    OverrideActionMessage,
    OverrideStyleMessage,
    REJECT_ACTION,
    RejectActionMessage,
    SET_OPTIONS,
    SET_SEED_DATA,
    SET_UPDATE_PAGE,
    SetOptionsMessage,
    SetSeedDataMessage,
    SetUpdatePageMessage,
} from 'sdk/messages/types';
import { Options } from 'sdk/reducers/page';

// MESSAGE CREATOR
export const overrideAction = (action: string): OverrideActionMessage => ({
    type: OVERRIDE_ACTION,
    action,
});

export const rejectAction = (action: string): RejectActionMessage => ({
    type: REJECT_ACTION,
    action,
});

export const overrideStyle = (): OverrideStyleMessage => ({
    type: OVERRIDE_STYLE,
});

export const getPageSize = (): GetPageSizeMessage => ({
    type: GET_PAGE_SIZE,
});

export const setOptions = (options: Options): SetOptionsMessage => ({
    type: SET_OPTIONS,
    options,
});

export const setUpdatePage = (nextUrl: string): SetUpdatePageMessage => ({
    type: SET_UPDATE_PAGE,
    nextUrl,
});

export const sendSeedData = (
    seedData: Record<string, any>,
): SetSeedDataMessage => ({
    type: SET_SEED_DATA,
    seedData,
});
