import { messagingIsEnabled } from 'sdk/reducers/page';

import {
    sendDisplayOptions,
    sendGetSizeRequest,
    sendOverriddenActions,
    sendOverrideStyle,
} from '../../sagas/actionCreators';

/**
 * Thunk called when the SDK bridge is fully operational
 * in Partoo App (ie. it is possible to post JS message to bridge)
 */
export const onIFrameJSHasLoaded = () => (dispatch: any) => {
    dispatch(messagingIsEnabled());
    dispatch(sendOverriddenActions());
    dispatch(sendDisplayOptions());
    dispatch(sendOverrideStyle());
    dispatch(sendGetSizeRequest());
};
