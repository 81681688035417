import { CONNECTION_PATH } from 'app/routing/routeIds';

import { SDKState, urlSelector } from 'sdk/reducers';
import { goToNextPage } from 'sdk/reducers/page';
import NavigationService from 'sdk/services/navigation';

import { sendUpdatePage } from '../../sagas/actionCreators';

/**
 * Thunk to go forward in navigation history.
 */
export const goForwardThunk = () => async (dispatch: any, getState: () => SDKState) => {
    const state = getState();
    const currentUrl = urlSelector(state);

    // If the current URL includes CONNECTION_PATH, navigate forward once
    if (currentUrl?.includes(CONNECTION_PATH)) {
        dispatch(goToNextPage());
    }

    // Dispatch goToNextPage action again to proceed forward
    dispatch(goToNextPage());
    const nextUrl = urlSelector(getState()); // get the updated URL after dispatch

    // If the URL has changed, update it with the short version
    if (nextUrl && currentUrl !== nextUrl) {
        const nextShortUrl = NavigationService.getShortUrlFromFullUrl(nextUrl);
        dispatch(sendUpdatePage(nextShortUrl));
    }
};
