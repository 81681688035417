export const IFRAME_JS_LOADED = 'IFRAME_JS_LOADED';
export const CALLBACK_CALLED = 'CALLBACK_CALLED';
export const STYLE_OVERRIDDEN = 'STYLE_OVERRIDDEN';
export const PAGE_CHANGED = 'PAGE_CHANGED';
export const PAGE_SIZE_GIVEN = 'PAGE_SIZE_GIVEN';
export const USER_IS_LOGGED_IN = 'USER_IS_LOGGED_IN';
export const USER_IS_LOGGED_OUT = 'USER_IS_LOGGED_OUT';
export const ERROR_400_OCCURRED_IN_IFRAME = 'ERROR_400_OCCURRED_IN_IFRAME';
export const ERROR_403_OCCURRED_IN_IFRAME = 'ERROR_403_OCCURRED_IN_IFRAME';
export const ERROR_404_OCCURRED_IN_IFRAME = 'ERROR_404_OCCURRED_IN_IFRAME';
export const ERROR_500_OCCURRED_IN_IFRAME = 'ERROR_500_OCCURRED_IN_IFRAME';
export const SEND_SEED_DATA_REACT_APP = 'SEND_SEED_DATA_REACT_APP';

export type SeedData = {
    name?: string;
    address?: string;
    city?: string;
    phone?: string;
    country?: string;
    zipcode?: string;
};

export type StyleIsOverriddenMessage = {
    type: 'STYLE_OVERRIDDEN';
};

export type CommunicateSeedDataToReactAppMessage = {
    type: 'SEND_SEED_DATA_REACT_APP';
    seed: SeedData;
};

export type GivePageHeightMessage = {
    type: 'PAGE_SIZE_GIVEN';
    height: number;
};

export type CallbackCalledMessage = {
    type: 'CALLBACK_CALLED';
    action: string;
    inputs: any;
};

export type PageHasChangedMessage = {
    type: 'PAGE_CHANGED';
    url: string;
};

export type IFrameJSHasLoadedMessage = {
    type: 'IFRAME_JS_LOADED';
};

export type UserIsLoggedInMessage = {
    type: 'USER_IS_LOGGED_IN';
};

export type UserIsLoggedOutMessage = {
    type: 'USER_IS_LOGGED_OUT';
};

type Error400Message = {
    type: 'ERROR_400_OCCURRED_IN_IFRAME';
};

type Error404Message = {
    type: 'ERROR_404_OCCURRED_IN_IFRAME';
};

type Error403Message = {
    type: 'ERROR_403_OCCURRED_IN_IFRAME';
};

type Error500Message = {
    type: 'ERROR_500_OCCURRED_IN_IFRAME';
};

/**
 * [LEGACY] Error posted by error pages. See files in `web/static/javascript/app/iframe`
 */
export type ErrorMessages = Error400Message | Error404Message | Error403Message | Error500Message;

export type BridgeMessage =
    | IFrameJSHasLoadedMessage
    | CallbackCalledMessage
    | PageHasChangedMessage
    | GivePageHeightMessage
    | CommunicateSeedDataToReactAppMessage
    | StyleIsOverriddenMessage
    | UserIsLoggedInMessage
    | UserIsLoggedOutMessage;
