import 'regenerator-runtime/runtime';
import { Store, applyMiddleware, compose, createStore } from 'redux';

import { DEBUG } from 'app/config';

import iframeWrapper, { IFrameWrapper } from './IFrameWrapper';
import MessageListener from './MessageListener';
import messageListenerMappers from './MessageListener.mappers';
import PartooSDK from './PartooSDK';
import sdkMappers from './PartooSDK.mappers';
import reducers, { SDKState } from './reducers';
import { Options, updateOptions } from './reducers/page';
import { connect } from './redux-sdk';
import { handleEventMiddleware, navigateMiddleware, sendMessageMiddleware } from './thunks';

type PartooSDKContainer = {
    sdk: PartooSDK;
    iframeWrapper: IFrameWrapper;
    messageListener: MessageListener;
    store: Store<SDKState>;
};

export type EventListener = (...args: any[]) => void;

/**
 * Create a container object containing all the services used by Partoo SDK:
 *   - `iFrameWrapper`: service handling HTML elements
 *   - `messageListener`: service listening to JS message from Partoo App to dispatch redux action
 *   - `store`: Redux store managing SDK state
 *   - `sdk`: Partoo SDK used by developers to integrate Partoo App in 3rd party websites
 *
 * @param elementId {string}
 * @param store {Store<SDKState>}
 * @returns {{
 *      iframeWrapper: IFrameWrapper,
 *      messageListener: MessageListener,
 *      sdk: PartooSDK,
 *      store: Store<SDKState>
 * }}
 */
const makeContainer = (elementId: string, store: Store<SDKState>): PartooSDKContainer => {
    const messageListener = new MessageListener();

    return {
        sdk: new PartooSDK(messageListener, elementId),
        messageListener,
        iframeWrapper,
        store,
    };
};

let container: PartooSDKContainer;

const asyncMiddleware = store => next => action => {
    if (typeof action === 'function') {
        // Call the function and pass dispatch and getState from the store
        return action(store.dispatch, store.getState);
    }

    // For plain object actions, just continue the middleware chain
    return next(action);
};

/**
 * Initialize SDK:
 *    - create Redux store
 *    - instantiate services
 *    - insert HTML elements
 *    - connect Redux store to services
 *
 * @param elementId {string} Id of the div inside which we are going to insert the HTML elements
 * @param options {Options} Display options
 */
const initializeSDK = (elementId: string, options: Options) => {
    // Create Store & dispatch first action
    const middlewares = [
        asyncMiddleware,
        handleEventMiddleware,
        sendMessageMiddleware,
        navigateMiddleware,
    ].filter(Boolean);
    let composeEnhancer = compose;

    if (process.env['NODE_ENV'] !== 'production') {
        composeEnhancer = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            : compose; // eslint-enable
    }

    const store: Store<SDKState> = createStore(
        reducers,
        composeEnhancer(applyMiddleware(...middlewares)),
    );

    store.dispatch(updateOptions(options));

    // Insert iFrame
    iframeWrapper.insert(elementId);
    container = makeContainer(elementId, store);

    // Connect instances to store
    connect(store, container.messageListener, ...messageListenerMappers);
    // @ts-expect-error
    connect(store, container.sdk, ...sdkMappers);
};

/**
 * Initialize and returns Partoo SDK. If `debugOn`, it returns the entire container instead.
 *
 * @param elementId {string} Id of the div inside which we are going to insert the HTML elements
 * @param options {Options} Display options
 * @param debugOn {boolean} If set to true, will return the container instead of the sdk instance.
 *                          By default, this param is set to `false` and it works only in dev mode.
 * @returns {PartooSDK|PartooSDKContainer}
 */
export const init = (
    elementId: string,
    options: Options,
    debugOn = false,
): PartooSDK | PartooSDKContainer => {
    initializeSDK(elementId, options);

    // we want debugOn to work only in dev mode
    if (DEBUG && debugOn) {
        return container;
    }

    return container.sdk;
};

/**
 * Destroy Partoo SDK
 *
 * @param callback {null|Function} Callback triggered once destroy is complete
 */
export const destroy = (callback: null | (() => void) = null) => {
    const destroyCallback = () => {
        if (callback) {
            callback();
        }

        // @ts-expect-error
        delete container.sdk;
        // @ts-expect-error
        delete container.messageListener;
        // @ts-expect-error
        delete container.store;
    };

    if (container.sdk !== null) {
        container.sdk.destroy(destroyCallback);
    }
};
