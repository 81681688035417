import { Dispatchers, State } from './PartooSDK';
import { SDKState, userStatusSelector } from './reducers';
import { Options, UserStatuses, messagingIsDisabled, updateOptions } from './reducers/page';
import {
    goBack,
    goForward,
    loginUser,
    logoutUser,
    navigateTo,
    sendDisplayOptions,
    sendRegisteredCallback,
    sendUnregisteredCallback,
} from './sagas/actionCreators';
import { AdditionalParams } from './services/navigation';

const mapReduxStateToClassState = (state: SDKState): State => ({
    isLoggingOut: userStatusSelector(state) === UserStatuses.LOGGING_OUT,
    isLoggingIn: userStatusSelector(state) === UserStatuses.LOGGING_IN,
});

const mapDispatchToDispatchers = (dispatch: any): Dispatchers => ({
    login: (userToken: string): void => dispatch(loginUser(userToken)),
    logout: (): void => dispatch(logoutUser()),
    setOptions: (options: Options): void => {
        dispatch(updateOptions(options));
        dispatch(sendDisplayOptions());
    },
    navigate: (
        route: string,
        seedData?: Record<string, any>,
        additionalParams?: AdditionalParams,
    ): void => dispatch(navigateTo(route, additionalParams, seedData)),
    back: () => dispatch(goBack()),
    forward: () => dispatch(goForward()),
    registerCallback: (action: string, callback: (...args: Array<any>) => any): void =>
        dispatch(sendRegisteredCallback(action, callback)),
    unregisterCallback: (action: string): void => dispatch(sendUnregisteredCallback(action)),
    disableMessaging: () => dispatch(messagingIsDisabled()),
});

export default [mapReduxStateToClassState, mapDispatchToDispatchers];
